import(/* webpackMode: "eager" */ "/codebuild/output/src2942667814/src/LPL_WEB/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2942667814/src/LPL_WEB/lib/notistack/index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/codebuild/output/src2942667814/src/LPL_WEB/lib/store/providers/Providers.js");
;
import(/* webpackMode: "eager", webpackExports: ["themeFont"] */ "/codebuild/output/src2942667814/src/LPL_WEB/lib/Theme.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2942667814/src/LPL_WEB/node_modules/@mui/material/styles/index.js");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2942667814/src/LPL_WEB/node_modules/next/font/local/target.css?{\"path\":\"app/layout.js\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../font/FilsonProRegular.otf\",\"weight\":\"400\",\"style\":\"normal\"},{\"path\":\"../font/FilsonProRegularItalic.otf\",\"weight\":\"400\",\"style\":\"italic\"},{\"path\":\"../font/FilsonProBold.otf\",\"weight\":\"700\",\"style\":\"normal\"},{\"path\":\"../font/FilsonProBoldItalic.otf\",\"weight\":\"700\",\"style\":\"italic\"}]}],\"variableName\":\"filson\"}");
